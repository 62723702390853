"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.request = exports.Model = exports.createApiModel = exports.createApi = exports.createApiProvider = exports.createUseApiHook = exports.Type = void 0;
var typebox_1 = require("@sinclair/typebox");
Object.defineProperty(exports, "Type", { enumerable: true, get: function () { return typebox_1.Type; } });
var hooks_1 = require("./hooks");
Object.defineProperty(exports, "createUseApiHook", { enumerable: true, get: function () { return hooks_1.createUseApiHook; } });
Object.defineProperty(exports, "createApiProvider", { enumerable: true, get: function () { return hooks_1.createApiProvider; } });
var api_1 = require("./api");
Object.defineProperty(exports, "createApi", { enumerable: true, get: function () { return api_1.createApi; } });
var model_1 = require("./model");
Object.defineProperty(exports, "createApiModel", { enumerable: true, get: function () { return model_1.createApiModel; } });
Object.defineProperty(exports, "Model", { enumerable: true, get: function () { return model_1.Model; } });
var request_1 = require("./request");
Object.defineProperty(exports, "request", { enumerable: true, get: function () { return request_1.request; } });
