"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createApiModel = exports.Model = void 0;
const react_1 = require("react");
const react_query_1 = require("@tanstack/react-query");
const mutation_1 = require("./mutation");
const request_1 = require("./request");
class Model {
    constructor(model) {
        Object.assign(this, model);
    }
}
exports.Model = Model;
const parseSearchQuery = (fields) => fields.reduce((acc, { name, is, isOneOf, contains }) => {
    if (typeof is !== "undefined") {
        return Object.assign(Object.assign({}, acc), { [name]: is });
    }
    if (typeof isOneOf !== "undefined") {
        return Object.assign(Object.assign({}, acc), { [name]: isOneOf.join(",") });
    }
    if (typeof contains !== "undefined") {
        return Object.assign(Object.assign({}, acc), { [name]: `%${contains}%` });
    }
    return acc;
}, {});
const buildResourcePath = (baseUrl, resource) => {
    const cleanBaseUrl = baseUrl.endsWith("/")
        ? baseUrl.substr(0, baseUrl.length - 1)
        : baseUrl;
    const cleanResource = resource.startsWith("/")
        ? resource.substr(1)
        : resource;
    return `${cleanBaseUrl}/${cleanResource}`;
};
function createApiModel({ name, resource, schema, idKey = "id", }) {
    const factoryFn = ({ client, baseUrl, token = (0, react_1.createRef)(), }) => {
        const modelKeys = {
            search: (params = {}) => [name, ...(params ? [params] : [])],
            get: (id) => [name, id],
        };
        const resourcePath = buildResourcePath(baseUrl, resource);
        const createMutation = (0, mutation_1.createCreateMutation)(name, {
            client,
            idKey,
            createFn: (0, request_1.createCreateRequestFn)({ resourcePath, token }),
            itemCacheKey: modelKeys.get,
            itemIndexCacheKey: modelKeys.search,
        });
        const updateMutation = (0, mutation_1.createUpdateMutation)(name, {
            client,
            idKey,
            updateFn: (0, request_1.createUpdateRequestFn)({
                resourcePath,
                idKey,
                token,
            }),
            itemCacheKey: modelKeys.get,
            itemIndexCacheKey: modelKeys.search,
        });
        const removeMutation = (0, mutation_1.createDeleteMutation)(name, {
            client,
            idKey,
            deleteFn: (0, request_1.createRemoveRequestFn)({
                resourcePath,
                token,
            }),
            itemCacheKey: modelKeys.get,
            itemIndexCacheKey: modelKeys.search,
        });
        const callQuery = (params, options) => {
            const fn = (0, request_1.createCallRequestFn)({
                resourcePath,
                token,
            });
            return (0, react_query_1.useQuery)(Object.assign({ queryKey: modelKeys.search(params), queryFn: () => fn(params), initialData: [] }, options));
        };
        const itemQuery = (id, options) => {
            const fn = (0, request_1.createGetRequestFn)({ resourcePath, token });
            return (0, react_query_1.useQuery)(Object.assign({ queryKey: modelKeys.get(id), queryFn: () => fn(id), initialData: [] }, options));
        };
        const allQuery = () => {
            const queryClient = (0, react_query_1.useQueryClient)();
            const fn = (0, request_1.createSearchRequestFn)({
                resourcePath,
                token,
            });
            return (0, react_query_1.useQuery)({
                placeholderData: (previousData) => previousData,
                queryKey: modelKeys.search({}),
                queryFn: () => __awaiter(this, void 0, void 0, function* () {
                    const { results } = yield fn();
                    results.forEach((item) => {
                        queryClient.setQueryData(modelKeys.get(item[idKey]), item);
                    });
                    return results;
                }),
            });
        };
        const paginatedQuery = ({ offset = 0, limit = 99, fields = [], orderBy, } = {}) => {
            const queryClient = (0, react_query_1.useQueryClient)();
            const fn = (0, request_1.createSearchRequestFn)({
                resourcePath,
                token,
            });
            return (0, react_query_1.useQuery)({
                placeholderData: (previousData) => previousData,
                queryKey: modelKeys.search({ offset, limit, orderBy, fields }),
                queryFn: () => __awaiter(this, void 0, void 0, function* () {
                    const { results, total } = yield fn(Object.assign({ limit,
                        offset,
                        orderBy }, parseSearchQuery(fields)));
                    results.forEach((item) => {
                        queryClient.setQueryData(modelKeys.get(item[idKey]), item);
                    });
                    return { results, total, offset, limit };
                }),
            });
        };
        const infiniteQuery = ({ offset = 0, orderBy, fields = [], } = {}) => {
            const limit = 99;
            const queryClient = (0, react_query_1.useQueryClient)();
            const fn = (0, request_1.createSearchRequestFn)({
                resourcePath,
                token,
            });
            return (0, react_query_1.useInfiniteQuery)({
                placeholderData: (previousData) => previousData,
                queryKey: modelKeys.search({ offset, limit, orderBy, fields }),
                queryFn: () => __awaiter(this, void 0, void 0, function* () {
                    const { results, total } = yield fn(Object.assign({ limit,
                        offset,
                        orderBy }, parseSearchQuery(fields)));
                    results.forEach((item) => {
                        queryClient.setQueryData(modelKeys.get(item[idKey]), item);
                    });
                    return { results, total, offset, limit };
                }),
                defaultPageParam: 0,
                getNextPageParam: ({ total, offset, limit }, pages) => {
                    const nextOffset = offset + limit;
                    if (nextOffset >= total) {
                        return undefined;
                    }
                    return nextOffset;
                },
            });
        };
        const model = new Model({
            schema: schema,
            create: createMutation,
            update: updateMutation,
            remove: removeMutation,
            get: itemQuery,
            call: callQuery,
            all: allQuery,
            search: paginatedQuery,
            infinite: infiniteQuery,
            invalidateOne: (id) => client.invalidateQueries({ queryKey: modelKeys.get(id) }),
            invalidateAll: () => client.invalidateQueries({ queryKey: modelKeys.search() }),
            read: (id) => client.getQueryData(modelKeys.get(id)),
            readAll: () => client.getQueryData(modelKeys.search()),
            readOneFromAll: (id) => {
                var _a;
                const all = (_a = client.getQueryData(modelKeys.search())) !== null && _a !== void 0 ? _a : [];
                return all.find((item) => item.id === id);
            },
        });
        return model;
    };
    return Object.assign(factoryFn, { schema });
}
exports.createApiModel = createApiModel;
