"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createRemoveRequestFn = exports.createUpdateRequestFn = exports.createCreateRequestFn = exports.createGetRequestFn = exports.createCallRequestFn = exports.createSearchRequestFn = exports.request = void 0;
const qs_1 = require("qs");
function request(url, { method = "get", headers = {}, query = {}, body, token, } = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        const cleanedQuery = Object.entries(query).reduce((acc, [key, val]) => {
            if (!val) {
                return acc;
            }
            return Object.assign(Object.assign({}, acc), { [key]: val });
        }, {});
        const queryString = (0, qs_1.stringify)(cleanedQuery);
        const uri = `${url}${!!queryString ? `?${queryString}` : ""}`;
        const resp = yield fetch(uri, {
            method,
            headers: Object.assign(Object.assign({ "Content-Type": "application/json" }, (!!token ? { Authorization: `Bearer ${token}` } : {})), headers),
            body: ["post", "put"].includes(method.toLowerCase())
                ? JSON.stringify(body)
                : undefined,
        });
        if (!resp.ok) {
            const { message } = yield resp.json();
            throw new Error(message);
        }
        const responseHeaders = {};
        for (const pair of resp.headers.entries()) {
            const [name, value] = pair;
            responseHeaders[name.toLowerCase()] = value;
        }
        const [offset, limit, total] = [
            responseHeaders["X-Pagination-Offset".toLowerCase()],
            responseHeaders["X-Pagination-Limit".toLowerCase()],
            responseHeaders["X-Pagination-Total".toLowerCase()],
        ];
        const respBody = (yield resp.json());
        return [
            respBody,
            {
                offset: parseInt(offset, 10),
                limit: parseInt(limit, 10),
                total: parseInt(total, 10),
            },
        ];
    });
}
exports.request = request;
function createSearchRequestFn({ resourcePath, token, }) {
    return function search(_a = {}) {
        var { offset = 0, limit = 999, orderBy } = _a, query = __rest(_a, ["offset", "limit", "orderBy"]);
        return __awaiter(this, void 0, void 0, function* () {
            const [results, { total }] = yield request(resourcePath, {
                method: "get",
                query,
                token: token.current,
                headers: Object.assign({ "X-Pagination-Offset": offset.toString(), "X-Pagination-Limit": limit.toString() }, (!!orderBy ? { "X-Pagination-OrderBy": orderBy } : {})),
            });
            return { results, total };
        });
    };
}
exports.createSearchRequestFn = createSearchRequestFn;
function createCallRequestFn({ resourcePath, token, }) {
    return function call(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const [resp] = yield request(resourcePath, {
                token: token.current,
                query: params,
            });
            return resp;
        });
    };
}
exports.createCallRequestFn = createCallRequestFn;
function createGetRequestFn(_a) {
    var { resourcePath, token } = _a, options = __rest(_a, ["resourcePath", "token"]);
    return function get(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const [resp] = yield request(`${resourcePath}/${id}`, Object.assign({ method: "get", token: token.current }, options));
            return resp;
        });
    };
}
exports.createGetRequestFn = createGetRequestFn;
function createCreateRequestFn({ resourcePath, token, }) {
    return function create(body) {
        return __awaiter(this, void 0, void 0, function* () {
            const [resp] = yield request(resourcePath, {
                method: "post",
                body,
                token: token.current,
            });
            return resp;
        });
    };
}
exports.createCreateRequestFn = createCreateRequestFn;
function createUpdateRequestFn(_a) {
    var { resourcePath, idKey = "id", token } = _a, options = __rest(_a, ["resourcePath", "idKey", "token"]);
    return function update(body) {
        return __awaiter(this, void 0, void 0, function* () {
            const [resp] = yield request(`${resourcePath}/${body[idKey]}`, Object.assign({ method: "put", body, token: token.current }, options));
            return resp;
        });
    };
}
exports.createUpdateRequestFn = createUpdateRequestFn;
function createRemoveRequestFn(_a) {
    var { resourcePath, token } = _a, options = __rest(_a, ["resourcePath", "token"]);
    return function remove(body) {
        return __awaiter(this, void 0, void 0, function* () {
            const [resp] = yield request(`${resourcePath}/${body.id}`, Object.assign({ method: "delete", body, token: token.current }, options));
            return resp;
        });
    };
}
exports.createRemoveRequestFn = createRemoveRequestFn;
