"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createApi = void 0;
function createApi({ models, client, baseUrl, token, }) {
    return Object.keys(models).reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key]: models[key]({
            client,
            baseUrl,
            token,
        }) })), {});
}
exports.createApi = createApi;
