"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDeleteMutation = exports.createUpdateMutation = exports.createCreateMutation = void 0;
const react_query_1 = require("@tanstack/react-query");
function replaceInWithBy(data, newItem, replaceBy) {
    return [
        ...data.filter((item) => {
            return item[replaceBy] !== newItem[replaceBy];
        }),
        newItem,
    ];
}
function createCreateMutation(name, { client, idKey = "id", createFn, itemCacheKey, itemIndexCacheKey, }) {
    const mutationName = `${name}_create`;
    client.setMutationDefaults([mutationName], {
        mutationFn: (item) => {
            return createFn(item);
        },
        onMutate: (item) => __awaiter(this, void 0, void 0, function* () {
            yield client.cancelQueries({
                queryKey: itemCacheKey(item[idKey]),
            });
            const previous = client.getQueryData(itemCacheKey(item[idKey]));
            client.setQueryData(itemCacheKey(item[idKey]), item);
            client.setQueryData(itemIndexCacheKey(), (oldData = []) => replaceInWithBy(oldData, item, idKey));
            client.setQueryData(itemCacheKey(item[idKey]), () => item);
            return { previous };
        }),
        onSuccess: (item) => {
            client.invalidateQueries({ queryKey: itemIndexCacheKey() });
            client.invalidateQueries({
                queryKey: itemCacheKey(item[idKey]),
            });
        },
        onError: (_err, item, context) => {
            if (!!(context === null || context === void 0 ? void 0 : context.previous)) {
                client.setQueryData(itemCacheKey(item[idKey]), context.previous);
                client.setQueryData(itemIndexCacheKey(), (oldData = []) => context.previous
                    ? replaceInWithBy(oldData, context.previous, idKey)
                    : []);
                client.setQueryData(itemCacheKey(item[idKey]), () => undefined);
            }
        },
    });
    return (options) => (0, react_query_1.useMutation)(Object.assign({ mutationKey: [mutationName] }, options));
}
exports.createCreateMutation = createCreateMutation;
function createUpdateMutation(name, { client, idKey = "id", updateFn, itemCacheKey, itemIndexCacheKey, }) {
    const mutationName = `${name}_update`;
    client.setMutationDefaults([mutationName], {
        mutationFn: (item) => {
            return updateFn(item);
        },
        onMutate: (item) => __awaiter(this, void 0, void 0, function* () {
            yield client.cancelQueries({
                queryKey: itemCacheKey(item[idKey]),
            });
            const previous = client.getQueryData(itemCacheKey(item[idKey]));
            client.setQueryData(itemCacheKey(item[idKey]), item);
            client.setQueryData(itemIndexCacheKey(), (oldData = []) => oldData.map((oldItem) => {
                const itemId = item[idKey];
                const oldItemId = oldItem[idKey];
                return itemId === oldItemId ? item : oldItem;
            }));
            return { previous };
        }),
        onSuccess: () => {
            client.invalidateQueries({ queryKey: itemIndexCacheKey() });
        },
        onError: (_err, item, context) => {
            if (!!(context === null || context === void 0 ? void 0 : context.previous)) {
                client.setQueryData(itemCacheKey(item[idKey]), context.previous);
                client.setQueryData(itemIndexCacheKey(), (oldData = []) => context.previous
                    ? replaceInWithBy(oldData, context.previous, idKey)
                    : []);
            }
        },
    });
    return (options) => (0, react_query_1.useMutation)(Object.assign({ mutationKey: [mutationName] }, options));
}
exports.createUpdateMutation = createUpdateMutation;
function createDeleteMutation(name, { client, idKey = "id", itemCacheKey, itemIndexCacheKey, deleteFn, }) {
    const mutationName = `${name}_delete`;
    client.setMutationDefaults([mutationName], {
        retry: false,
        mutationFn: (item) => deleteFn(item),
        onMutate: (item) => __awaiter(this, void 0, void 0, function* () {
            yield client.cancelQueries({
                queryKey: itemCacheKey(item[idKey]),
            });
            const previous = client.getQueryData(itemCacheKey(item[idKey]));
            client.setQueryData(itemCacheKey(item[idKey]), null);
            client.setQueryData(itemIndexCacheKey(), (oldData = []) => oldData.filter((old) => old[idKey] !==
                item[idKey]));
            return { previous };
        }),
        onSuccess: () => {
            client.invalidateQueries({ queryKey: itemIndexCacheKey() });
        },
        onError: (_err, item, context) => {
            if (typeof (context === null || context === void 0 ? void 0 : context.previous) !== "undefined") {
                client.setQueryData(itemCacheKey(item[idKey]), context.previous);
                client.setQueryData(itemIndexCacheKey(), (oldData = []) => context.previous
                    ? replaceInWithBy(oldData, context.previous, idKey)
                    : []);
            }
        },
    });
    return (options) => (0, react_query_1.useMutation)(Object.assign({ mutationKey: [mutationName] }, options));
}
exports.createDeleteMutation = createDeleteMutation;
